import { useState } from 'react';
import useTranslation from 'next-translate/useTranslation';
import dynamic from 'next/dynamic';
import NextLink from 'next/link';

import { Button, Icon, Link } from '@/atoms';
import { Card } from '@/molecules';

import Agent from '@/assets/icons/Agent.svg';
import ArrowDownPri from '@/assets/icons/ArrowDownPri.svg';
import Bathroom from '@/assets/icons/Bathroom.svg';
import RoomSize from '@/assets/icons/RoomSize.svg';

import type { FunctionComponent } from 'react';
import type { TypeApartmentCardProps } from './types';

const PopUpBookingLavanda = dynamic(
  () => import('@/organisms/pop-up-booking-lavanda'),
  {
    ssr: false,
  }
);

const PopUpIframe = dynamic(
  () => import('@/atoms/multimedia/Iframe/PopUpIframe'),
  {
    ssr: false,
  }
);
/**
 * ApartmentsCard
 */
export const ApartmentCard: FunctionComponent<TypeApartmentCardProps> = ({
  className = '',
  images,
  title,
  desc,
  size,
  people,
  bathrooms,
  equipment,
  features,
  price,
  tourLink,
  accommodationSelect,
  buildingSelect,
  disabled = false,
  buttonText,
  buttonLink = '',
  metersFrom,
  buttonWidth = 100,
  cardOrientation = 'column',
  lavandaId,
  buildingId,
  landing = false,
}: TypeApartmentCardProps) => {
  const { t } = useTranslation('common');
  const [expanded, setExpanded] = useState(false);
  const [formOpen, setFormOpen] = useState(false);
  const [iframePopUpState, setIframePopUpState] = useState(false);

  const popUpToggle = () => {
    setFormOpen(!formOpen);
  };

  const iframeToggle = () => {
    setIframePopUpState(!iframePopUpState);
  };
  // TODO: CAMBIAR PARA APARTMENTS
  // features = [];

  return (
    <>
      <PopUpBookingLavanda
        apartmentsByBuilding={accommodationSelect}
        buildings={buildingSelect}
        show={formOpen}
        onClose={popUpToggle}
        defaultBuilding={buildingId}
        defaultApartment={lavandaId}
      />
      {tourLink && (
        <PopUpIframe
          setState={iframeToggle}
          src={tourLink}
          state={iframePopUpState}
        />
      )}

      <Card
        className={`h-auto text-primary-500 md:!h-full ${className} `}
        classNames={{
          multimedia:
            cardOrientation === 'row' ? 'h-[205px] md:h-full' : 'h-[205px]',
        }}
        multimediaProps={{ images: images, roundedPosition: 'top' }}
        backgroundColor="white"
        orientation={cardOrientation}
      >
        <div
          className={`${
            cardOrientation === 'row'
              ? ' flex h-auto flex-col justify-between md:h-full '
              : ' flex h-full flex-col justify-between '
          }`}
        >
          <div>
            <div className="mb-3 flex flex-col gap-4">
              <h3 className="text-heading text-heading--h4">{title}</h3>
              <p className="text-body text-body--primary">{desc}</p>
            </div>
          </div>
          <div className="flex flex-col gap-3">
            <div className="text-body text-body--secondary flex flex-row justify-between border-b border-primary-400 p-2">
              <div
                className={`${
                  cardOrientation === 'column'
                    ? 'flex flex-row gap-5'
                    : 'flex flex-col gap-5'
                }`}
              >
                {size && (
                  <div className="flex flex-row items-center gap-2">
                    <RoomSize width={20} />
                    <span>
                      {metersFrom &&
                        t('components.organisms.apartmentsCard.metersFrom')}
                      {size}m²
                    </span>
                  </div>
                )}
                {people && (
                  <div className="flex flex-row items-center gap-2">
                    <Agent width={20} />
                    <span>{people}</span>
                  </div>
                )}

                {bathrooms && (
                  <div className="flex flex-row items-center gap-2">
                    <Bathroom width={20} />
                    <span>{bathrooms}</span>
                  </div>
                )}
              </div>

              {(equipment?.length > 0 || features?.length > 0) && (
                <div
                  className="text-links text-links--small flex cursor-pointer flex-row items-center"
                  onClick={() => setExpanded(!expanded)}
                >
                  <span className="pr-2">
                    {!expanded
                      ? t('components.organisms.apartmentsCard.seeMore')
                      : t('components.organisms.apartmentsCard.seeLess')}
                  </span>
                  <ArrowDownPri
                    width={15}
                    height={21}
                    className={`${expanded ? '-rotate-180' : ''} `}
                  />
                </div>
              )}
            </div>

            {(equipment?.length > 0 || features?.length > 0) && (
              <div className={`${expanded ? 'flex flex-col gap-3' : 'hidden'}`}>
                {equipment?.length > 0 && (
                  <div>
                    <span className="text-subtitle text-subtitle--small font-bold">
                      {t('components.organisms.apartmentsCard.equipment')}
                    </span>
                    {equipment.map((item, index) => (
                      <div
                        key={index}
                        className="flex flex-row items-center gap-2"
                      >
                        <Icon src={item.icon} width={20} />
                        <span>{item.text}</span>
                      </div>
                    ))}
                  </div>
                )}
                {features &&
                  features.map((item, index) => (
                    <div className="flex flex-col gap-2" key={index}>
                      <span className="text-subtitle text-subtitle--small font-bold">
                        {item.name}
                      </span>
                      <div className="flex flex-col gap-1">
                        {item.features.map((feat, featIndex) => (
                          <div
                            key={featIndex}
                            className="flex flex-row items-center gap-2"
                          >
                            <Icon src={feat.icon} width={20} height={20} />
                            <span className="text-normal">{feat.text}</span>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
              </div>
            )}

            <div className="flex flex-col gap-3">
              <div className="flex flex-row justify-between">
                {price && (
                  <div className="flex flex-col">
                    <span className="text-body text-body--secondary">
                      {t('components.organisms.apartmentsCard.from')}
                    </span>
                    <div className="flex flex-row">
                      <span className="text-subtitle text-subtitle--small">
                        {price}
                      </span>
                      <span className="text-body text-body--primary">
                        {t('components.organisms.apartmentsCard.perMonth')}
                      </span>
                    </div>
                  </div>
                )}

                {tourLink && (
                  <Link
                    as="button"
                    variant="primary"
                    arrow={false}
                    underline={true}
                    onClick={iframeToggle}
                  >
                    {t('components.organisms.apartmentsCard.tourLink')}
                  </Link>
                )}
              </div>

              {landing ? (
                <NextLink href="#Contacto" prefetch={false} passHref>
                  <a>
                    <Button
                      variant="secondary"
                      size="large"
                      style={{ width: `${buttonWidth}%` }}
                    >
                      {buttonText}
                    </Button>
                  </a>
                </NextLink>
              ) : (
                buttonLink &&
                buttonText && (
                  <Button
                    variant="secondary"
                    size="large"
                    aria-disabled={disabled}
                    style={{ width: `${buttonWidth}%` }}
                    onClick={popUpToggle}
                  >
                    {buttonText}
                  </Button>
                )
              )}
            </div>
          </div>
        </div>
      </Card>
    </>
  );
};

ApartmentCard.displayName = 'ApartmentCard';
